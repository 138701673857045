import { NavFooterAccordionComponent } from '@fc-core/navbar/nav-footer-accordion/nav-footer-accordion.component';
import {
  defaultNavigation,
  filterNavigation,
} from '@fc-core/navbar/navigation/navigation.data';
import { ThumbnailsPipeModule } from '@fc-shared/pipes/thumbnails-pipe/thumbnails-pipe.module';
import { NavigationItem } from '@fc-core/navbar/navigation/navigation-item';
import {
  Component,
  effect,
  ElementRef,
  inject,
  Renderer2,
  Signal,
} from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { ViewBreakpoints } from '@fc-shared/constants';
import { combineLatest, Observable, Subject } from 'rxjs';
import { DialogsService, Permission } from '@fc-core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';
import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  Overlay,
} from '@angular/cdk/overlay';
import { NavigationItemComponent } from '@fc-core/navbar/navigation/navigation-item/navigation-item.component';

import {
  currentUserHasLegacyPermission,
  getCurrentEmployeePermissions,
  getShowNews,
  setNavbarState,
} from '../../../store';
import { getFeatures } from '@fc-store/selectors/features.selectors';
import { HasFeatureModule } from '@fc-shared/directives/has-feature/has-feature.module';
import { NotificationsModule } from '@fc-core/notifications/notifications.module';
import { getNavbarState } from '@fc-store/selectors/ui.selector';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { IconButtonComponent } from '@fc-shared/ui/buttons/icon-button.component';
import { MatTooltip } from '@angular/material/tooltip';
import { cloneDeep } from 'lodash';
import { CustomKeybindingDirective } from '../../../dialogs/customer-chooser-dialog/custom-keybinding.directive';
import { DevCustomerSelectComponent } from '../../../dialogs/customer-chooser-dialog/dev-customer-select/dev-customer-select.component';

@Component({
  selector: 'fc-navigation',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatDividerModule,
    MatMenuModule,
    RouterLink,
    ThumbnailsPipeModule,
    MatButtonModule,
    RouterLinkActive,
    NavFooterAccordionComponent,
    MatRippleModule,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    NavigationItemComponent,
    HasFeatureModule,
    NotificationsModule,
    IconComponent,
    IconButtonComponent,
    MatTooltip,
    CustomKeybindingDirective,
  ],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  store = inject(Store);
  router = inject(Router);
  el = inject(ElementRef);
  overlay = inject(Overlay);
  dialogsService = inject(DialogsService);
  breakPoint = inject(BreakpointObserver);
  renderer = inject(Renderer2);
  elementRef = inject(ElementRef);
  navbarState = this.store.selectSignal(getNavbarState);
  newsToShowState: Signal<boolean> = this.store.selectSignal(getShowNews);
  features: Signal<string[]> = this.store.selectSignal(getFeatures);
  currentPermissions: Signal<Permission[]> = this.store.selectSignal(
    getCurrentEmployeePermissions,
  );
  canSwitchCustomer = this.store.selectSignal(
    currentUserHasLegacyPermission(
      'accounting_customer_can_switch_customer_view',
    ),
  );
  readonly isMobile$: Observable<boolean> = this.breakPoint
    .observe([ViewBreakpoints.smSize])
    .pipe(map((result) => result.matches));
  defaultNavigationItems: NavigationItem[] = this.getNavigationList();
  showNotifications = false;
  destroy$ = new Subject();

  constructor() {
    // listen to router events to close the navigation
    combineLatest([this.router.events, this.isMobile$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([_, isMobile]) => (isMobile ? this.close() : null));
    // check if permissions or features is changed
    effect(() => (this.defaultNavigationItems = this.getNavigationList()));
    effect(() => {
      const navbarState = this.navbarState();

      if (navbarState) {
        this.closeAllAccordions();
        this.closeAllInnerNavs();
      }
    });
  }

  get isOpen(): boolean {
    return this.navbarState();
  }

  close(): void {
    this.store.dispatch(setNavbarState({ state: false }));
  }

  open(): void {
    this.store.dispatch(setNavbarState({ state: true }));
  }

  private closeAllInnerNavs(): void {
    this.defaultNavigationItems?.forEach((nav) => (nav.open = false));
  }

  onHover(event: Event): void {
    const navItemLink = event.currentTarget as HTMLElement;
    const innerNavFloatContainer = Array.from(navItemLink.children).find(
      (child) => child.classList.contains('inner-nav-float-container'),
    ) as HTMLElement;
    if (innerNavFloatContainer) {
      const rect = navItemLink.getBoundingClientRect();
      this.renderer.setStyle(innerNavFloatContainer, 'top', `${rect.top}px`);
    }
  }

  getNavigationList(): NavigationItem[] {
    const newsToShow = this.newsToShowState(),
      permissions = this.currentPermissions(),
      features = this.features(),
      navigation = cloneDeep(defaultNavigation);

    if (permissions !== null && features !== null)
      return filterNavigation(navigation, permissions, features, newsToShow);
  }

  toggleAccordion(event: Event, nav: NavigationItem): void {
    if (!nav.inner || !this.isOpen) return;
    const header: HTMLElement = event.currentTarget as HTMLElement,
      body: HTMLElement = header.parentElement.querySelector(
        '.inner-nav',
      ) as HTMLElement;
    if (header.classList.contains('open')) {
      this.closeAccordion(header, body);
      return;
    }
    this.closeAllAccordions();
    this.openAccordion(header, body);
  }

  private closeAllAccordions(): void {
    this.el.nativeElement
      .querySelectorAll('.inner-nav')
      .forEach((innerNav: HTMLElement) => {
        this.closeAccordion(
          (innerNav.parentElement as HTMLElement).querySelector(
            'fc-navigation-item',
          ),
          (innerNav.parentElement as HTMLElement).querySelector('.inner-nav'),
        );
      });
  }

  private closeAccordion(header: HTMLElement, body: HTMLElement): void {
    header.classList.remove('open');
    body.style.maxHeight = '0px';
  }

  private openAccordion(header: HTMLElement, body: HTMLElement): void {
    header.classList.add('open');
    body.style.maxHeight = `${body.scrollHeight}px`;
  }

  openCustomerSwitch() {
    this.dialogsService.openFullScreenDialog(DevCustomerSelectComponent);
  }
}
