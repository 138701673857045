import { Directive, inject, Input, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalSearchService } from '@fc-shared/modules/global-search/services/global-search.service';
import {
  SearchCategory,
  SearchCategoryEnum,
} from '@fc-shared/modules/global-search/models/category';

@Directive({
  selector: '[fcResultItem]',
})
export class ResultItemDirective<T> {
  @Input() result: T;
  @Input() search: string;
  @Input() searchCategory: SearchCategory;
  @Input() set indexObject(i: {
    itemIndex: number;
    currentIndex: { itemIndex: number; arrayIndex: number };
  }) {
    if (this.searchCategory.value === 'all') {
      this.isItemSelected =
        this.category ===
          this.globalSearchService.selectedIndex()?.arrayIndex &&
        i.itemIndex === this.globalSearchService.selectedIndex()?.itemIndex;
    } else {
      this.isItemSelected =
        i.itemIndex === this.globalSearchService.selectedIndex()?.itemIndex;
    }

    if (this.isItemSelected) {
      this.globalSearchService.selectedItemLink.set(this.itemLink);
      this.scrollToSelected();
    }
  }
  public category: SearchCategoryEnum;
  readonly router: Router = inject(Router);
  readonly globalSearchService: GlobalSearchService =
    inject(GlobalSearchService);
  readonly viewContainerRef: ViewContainerRef = inject(ViewContainerRef);
  itemLink: string;
  isItemSelected: boolean;

  onItemClick() {
    this.router.navigate([this.itemLink]);
    this.globalSearchService.closeSearch();
  }

  scrollToSelected() {
    this.viewContainerRef.element.nativeElement.scrollIntoView({
      block: 'nearest',
    });
  }
}
