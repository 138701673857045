import { Directive, HostListener, input, output } from '@angular/core';

@Directive({
  selector: '[fcCustomKeybinding]',
  standalone: true,
})
export class CustomKeybindingDirective {
  key = input.required<string>();
  withCtrl = input<boolean>();
  withShift = input<boolean>();
  disabled = input<boolean>();
  keyTrigger = output();

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (this.disabled()) return;

    const keyMatches = event.key === this.key();
    const ctrlMatches = this.withCtrl() && event.ctrlKey;
    const shiftMatches = this.withShift() && event.shiftKey;

    if (
      keyMatches &&
      ((ctrlMatches && shiftMatches) ||
        ctrlMatches ||
        shiftMatches ||
        (!this.withCtrl() && !this.withShift()))
    ) {
      this.onKeydown(event);
    }
  }

  onKeydown(event: KeyboardEvent) {
    this.keyTrigger.emit();
    event.preventDefault();
  }
}
