import { Component, inject, Input } from '@angular/core';
import { MomentService } from '@fc-core';
import { EventHistoryItem } from '@fc-core/notifications/models/event-history-item';

@Component({
  selector: 'fc-event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.scss'],
})
export class EventItemComponent {
  momentService = inject(MomentService);

  @Input({ alias: 'event', required: true }) set setEvent(
    event: EventHistoryItem,
  ) {
    this.event = event;
    this.getTimeAgo();
  }

  event: EventHistoryItem;
  timeAgo: string;

  getTimeAgo(): void {
    this.timeAgo = this.momentService.timeAgo(this.event.created);
  }
}
