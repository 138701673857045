import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  takeUntil,
} from 'rxjs/operators';
import { fromEvent, Subject } from 'rxjs';
import { searchCategories } from '@fc-shared/modules/global-search/components/global-search-input/categories';
import { SearchCategory } from '@fc-shared/modules/global-search/models/category';
import { GlobalSearchService } from '@fc-shared/modules/global-search/services/global-search.service';

@Component({
  selector: 'fc-global-search-input',
  templateUrl: './global-search-input.component.html',
  styleUrls: ['./global-search-input.component.scss'],
})
export class GlobalSearchInputComponent implements AfterViewInit, OnDestroy {
  @Input() searchTerm: string;
  @Output() searchValue = new EventEmitter<{
    search: string;
    category: SearchCategory;
  }>();
  @Output() clearOffset = new EventEmitter<void>();
  @ViewChild('searchInputRef') searchInputRef: ElementRef<HTMLInputElement>;
  categories: SearchCategory[] = searchCategories;
  readonly globalSearchService: GlobalSearchService =
    inject(GlobalSearchService);
  searchInput = '';
  categorySelected: SearchCategory = this.categories[0];
  private destroy$ = new Subject<void>();

  ngAfterViewInit(): void {
    addEventListener('keydown', this.disableArrowEvents);
    fromEvent(this.searchInputRef.nativeElement, 'keyup')
      .pipe(
        debounceTime(100),
        filter(
          (event: KeyboardEvent) =>
            event.key !== 'ArrowLeft' &&
            event.key !== 'ArrowRight' &&
            event.key !== 'ArrowUp' &&
            event.key !== 'ArrowDown' &&
            event.key !== 'Enter',
        ),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.searchEvent();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    removeEventListener('keydown', this.disableArrowEvents);
  }

  disableArrowEvents = (event: KeyboardEvent) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  };

  selectCategory(category: SearchCategory) {
    this.categorySelected = category;
    this.searchEvent();
    this.clearOffset.emit();
    this.globalSearchService.selectedIndex.set(null);
  }

  searchEvent() {
    this.globalSearchService.resetIndex();
    this.searchValue.emit({
      search: this.searchInput,
      category: this.categorySelected,
    });
  }
}
