import { AfterViewInit, Component, inject, Input } from '@angular/core';
import { LastActivity } from '@fc-locations/models/location-interface';
import { LocationType } from '@fc-locations/services/location.service';
import { MomentService } from '@fc-core';
import { highlightSearch } from '@fc-shared/utils/data.utils';
import { ResultItemDirective } from '@fc-shared/modules/global-search/components/global-search-results/result-item.directive';
import { SearchCategoryEnum } from '@fc-shared/modules/global-search/models/category';
import { platformLinks } from '@fc-shared/modules/global-search/services/utils';
import { SearchLocationResult } from '@fc-shared/modules/global-search/models/search-results.response';

@Component({
  selector: 'fc-location-result-item',
  templateUrl: './location-result-item.component.html',
  styleUrls: [
    './location-result-item.component.scss',
    '../global-search-results.component.scss',
  ],
})
export class LocationResultItemComponent
  extends ResultItemDirective<SearchLocationResult>
  implements AfterViewInit
{
  @Input() locationTypes: LocationType[];
  readonly moment = inject(MomentService);
  public locationType: LocationType;
  lastActivity: LastActivity;
  locationName: string;
  category = SearchCategoryEnum.Location;

  ngAfterViewInit(): void {
    this.locationType = this.locationTypes.find(
      (locationType) =>
        locationType.name.toLowerCase() === this.result.type.toLowerCase(),
    );
    this.setLocationName();
    this.itemLink = platformLinks.location(+this.result.id);
  }

  setLocationName(): string {
    if (!this.search) return this.result.name;
    this.locationName = highlightSearch(this.search, this.result.name);
  }
}
