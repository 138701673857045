import { AppSettingsService } from '@fc-core/services/app-settings.service';
import { AnalyticsService } from '@fc-core/services/analytics.service';
import { AppSettings } from '@fc-core/models/app.settings';
import { Injectable } from '@angular/core';
import { AuthService } from '@fc-core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import Hotjar from '@hotjar/browser';

@Injectable()
export class AppInitService {
  constructor(
    private auth: AuthService,
    private appSettingsService: AppSettingsService,
    private analyticsService: AnalyticsService,
  ) {}

  init(): Observable<AppSettings> {
    if (environment.environment !== 'local') {
      this.analyticsService.initGoogleAnalytics();
      this.analyticsService.setUpConfig();
      this.analyticsService.setAppVersion();
      this.analyticsService.initZendesk();
    }

    if (environment.hotjar && environment.environment !== 'local') {
      Hotjar.init(environment.hotjar.siteId, environment.hotjar.version);
    }

    if (this.auth.loggedIn && this.auth.tokenData?.timezone) {
      return this.appSettingsService.getAppSettings();
    }
  }
}
