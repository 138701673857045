<fc-dialog-header title="Choose customer"></fc-dialog-header>
<div class="list-container body p-24">
  <fc-form-field>
    <input
      #search
      (input)="store.setCustomerSearch(search.value)"
      fcFocusOnAppear
      fcInput
      placeholder="Search your organization"
    />
    <fc-icon icon="icon-search" fcPrefix></fc-icon>
  </fc-form-field>
  <div class="text-lg p-t-24">Organizations</div>
  <div
    (indexChange)="onCustomerSelectChange($event)"
    (selectItem)="
      store.switchCustomer(store.customerList()[store.customerIndex()])
    "
    [disabled]="store.isOpen()"
    [selectedIndex]="store.customerIndex()"
    fcArrowKeyNavigation
    fcCustomKeybinding
    key="e"
    [withCtrl]="true"
    (keyTrigger)="
      showEmployees(store.customerList()[store.customerIndex()].id, trigger())
    "
    class="list"
  >
    @if (store.customerLoading()) {
      <fc-skeleton-list [count]="4"></fc-skeleton-list>
    }
    @if (store.customerList().length === 0 && !store.customerLoading()) {
      <fc-empty-state title="No customers found" />
    }
    @for (customer of store.customerList(); track i; let i = $index) {
      <div
        class="item"
        #customerItem
        [class.active]="store.customerIndex() === i"
      >
        <div (click)="store.switchCustomer(customer)" class="item-description">
          <div class="flex-row justify-between flex-1">
            <div class="flex-row align-center gap-8">
              <fc-icon icon="icon-business"></fc-icon>
              <span class="text-lg">{{ customer.name }}</span>
            </div>
            <span class="text-md color-grey-400"
              >{{ customer.employeeCount }} emp</span
            >
          </div>
        </div>

        <fc-icon-outline-button
          size="large"
          color="secondary"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
          (click)="showEmployees(customer.id, trigger)"
        >
          <fc-icon icon="icon-group"></fc-icon>
        </fc-icon-outline-button>
      </div>
    }
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger()"
  [cdkConnectedOverlayOpen]="store.isOpen()"
  (detach)="store.setEmployeeSelectorOpen(false)"
  (backdropClick)="store.setEmployeeSelectorOpen(false)"
  [cdkConnectedOverlayBackdropClass]="['cdk-overlay-transparent-backdrop']"
  [cdkConnectedOverlayHasBackdrop]="true"
>
  <div class="employee-selector">
    <div class="search">
      <fc-form-field>
        <input
          #search
          (input)="store.setEmployeeSearch(search.value)"
          fcFocusOnAppear
          fcInput
          placeholder="Search employees"
        />
        <fc-icon icon="icon-search" fcPrefix></fc-icon>
      </fc-form-field>

      <div
        fcArrowKeyNavigation
        [selectedIndex]="store.employeeIndex()"
        (indexChange)="store.setEmployeeIndex($event)"
        (selectItem)="
          store.switchEmployee(store.employeeList()[store.employeeIndex()])
        "
        class="employee-list"
      >
        @if (store.employeeList().length === 0 && !store.employeeLoading()) {
          <fc-empty-state title="No employees found" />
        }
        @if (store.employeeLoading()) {
          <fc-skeleton-list [count]="4"></fc-skeleton-list>
        }
        @for (employee of store.employeeList(); track i; let i = $index) {
          <div
            [class.active]="store.employeeIndex() === i"
            (click)="store.switchEmployee(employee)"
            class="employee-item"
          >
            <div class="employee-avatar">
              <fc-user-avatar
                [fontSize]="10"
                [size]="20"
                [employee]="employee"
              ></fc-user-avatar>
            </div>
            <div class="employee-name">
              <span>{{ employee.firstName }} {{ employee.lastName }}</span>
              @if (employee.isAdmin) {
                <fc-tag backgroundColor="error100" textColor="error500"
                  >Admin
                </fc-tag>
              }
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</ng-template>
