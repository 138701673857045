import { Component, HostBinding, inject, Input } from '@angular/core';
import { NavigationItem } from '@fc-core/navbar/navigation/navigation-item';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';

@Component({
  selector: 'fc-navigation-item',
  template: `
    <div *ngIf="haveNotifications" class="notification">
      <div class="notification-count"></div>
    </div>
    <fc-icon
      [size]="isOpen ? 20 : 24"
      *ngIf="nav.icon"
      [icon]="nav.icon"
    ></fc-icon>
    <div class="nav-item-text">{{ nav.name }}</div>
    <fc-icon
      *ngIf="nav.inner && isOpen"
      [class.collapsed]="nav.open"
      class="nav-item-arrow"
      icon="icon-arrow_drop_up"
    ></fc-icon>
  `,
  styleUrls: ['./navigation-item.component.scss'],
  imports: [RouterLink, RouterLinkActive, MatIconModule, NgIf, IconComponent],
  standalone: true,
})
export class NavigationItemComponent {
  @Input({ required: true }) nav: NavigationItem;
  @Input() haveNotifications: boolean;
  @HostBinding('class.inner') @Input() inner: boolean;
  @HostBinding('class.nav-open') @Input() isOpen = true;

  private readonly router = inject(Router);

  @HostBinding('class.active') get itemActive() {
    if (this.nav.inner) {
      return this.nav.inner.some((item) => item.link === this.router.url);
    } else {
      return this.router.url.includes(this.nav.link);
    }
  }
}
