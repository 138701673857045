import { AfterViewInit, Component, Input } from '@angular/core';
import { ResultItemDirective } from '@fc-shared/modules/global-search/components/global-search-results/result-item.directive';
import { highlightSearch } from '@fc-shared/utils/data.utils';
import { Company } from '@fc-store/model/company.model';
import { SearchCategoryEnum } from '@fc-shared/modules/global-search/models/category';
import { platformLinks } from '@fc-shared/modules/global-search/services/utils';
import { SearchCRMResult } from '@fc-shared/modules/global-search/models/search-results.response';

@Component({
  selector: 'fc-crm-result-item',
  templateUrl: './crm-result-item.component.html',
  styleUrls: [
    './crm-result-item.component.scss',
    '../global-search-results.component.scss',
  ],
})
export class CrmResultItemComponent
  extends ResultItemDirective<SearchCRMResult>
  implements AfterViewInit
{
  @Input() companyList: Company[];
  name = '';
  category = SearchCategoryEnum.Crm;
  avatarPlaceholderText = '';

  ngAfterViewInit() {
    this.setName();
    this.itemLink = platformLinks.crm(
      +this.result.id,
      this.result.type === 'company' ? 'companies' : 'contacts',
    );
    this.avatarPlaceholderText = this.result?.name
      .split(' ')
      .map((word) => word[0])
      .join('');
  }

  setName(): void {
    this.name = highlightSearch(this.search, this.result?.name);
  }
}
